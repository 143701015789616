import React from "react"

// Components
import ProductInfo from "../ProductInfo/ProductInfo"

/**
 * @param shopifyProduct - shopify data for the product
 * @param product - static data for the product
 * @param hero - is it in hero or not
 * */
const BuyBox = ({ shopifyProduct, product, hero }) => {
  return (
    <>
      {hero ? (
        <section className="bg--section" id="start-now">
          <div className="container">
            <ProductInfo isPopup={false} shopifyProduct={shopifyProduct} product={product} order="1" />
          </div>
        </section>
      ) : (
        <section className="container border-bottom-1  border-light-gray">
          <ProductInfo isPopup={false} shopifyProduct={shopifyProduct} product={product} order="2" />
        </section>
      )}
    </>
  )
}
export default BuyBox
