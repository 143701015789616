import React, { useEffect, useState, Suspense } from "react"

// Provider
import { useTheme } from "../context/ThemeContext"

// Utilities
import lazyLoad from "../utilities/handleLazyLoad"

// Data
import FULL_PRODUCT_DATA from "../../static/data/full-product"

// Components
import BuyBox from "../components/ProductBuyBox/BuyBox"
const Loader = lazyLoad(() => import("../components/Loader/Loader"))
const ScrollTopButton = lazyLoad(() => import("../components/Scrollable/ScrollTopButton"))
const SuggestedProducts = lazyLoad(() => import("../components/SuggestedProducts/SuggestedProducts"))
const References = lazyLoad(() => import("../components/References/References"))

// Product Content - Supplements
const BerryGreenProtein = lazyLoad(() => import("../products/berry-green-protein"))
const RockAndRestore = lazyLoad(() => import("../products/rock-and-restore"))
const FullBodyCollagen = lazyLoad(() => import("../products/full-body-collagen"))
const VanillaProtein = lazyLoad(() => import("../products/vanilla-protein"))
const SereniCalm = lazyLoad(() => import("../products/serenicalm"))
const WholeSleep = lazyLoad(() => import("../products/whole-sleep"))
// Product Content - Bundles
const VanillaProteinPromo = lazyLoad(() => import("../products/vanilla-protein-promo"))
const BerryGreenProteinPromo = lazyLoad(() => import("../products/berry-green-protein-promo"))
const RockAndRestorePromo = lazyLoad(() => import("../products/rock-and-restore-promo"))
const FullBodyCollagenPromo = lazyLoad(() => import("../products/full-body-collagen-promo"))
const FourPillarsOfHealthPromo = lazyLoad(() => import("../products/4-pillars-of-health-promo"))
const VanillaProteinChallenger = lazyLoad(() => import("../products/vanilla-protein-challenger"))
const ChocolateProtein = lazyLoad(() => import("../products/chocolate-protein"))
const WorkoutPromo = lazyLoad(() => import("../products/workout-promo"))
const ChocolateProteinPromo = lazyLoad(() => import("../products/chocolate-protein-promo"))
const VanillaProteinBuy1Get1Free = lazyLoad(() => import("../products/vanilla-protein-buy-3-get-1-free"))
const ChocolateProteinBuy3Get1Free = lazyLoad(() => import("../products/chocolate-protein-buy-3-get-1-free"))
const RockAndRestoreBuy3Get1Free = lazyLoad(() => import("../products/rock-and-restore-aminos-buy-3-get-1-free"))
const FullBodyCollagenBuy3Get1Free = lazyLoad(() => import("../products/full-body-collagen-buy-3-get-1-free"))
const BerryGreenProteinBuy3Get1Free = lazyLoad(() => import("../products/berry-green-protein-buy-3-get-1-free"))
const NeapolitanBundle = lazyLoad(() => import("../products/neapolitan-promo"))

const ProductTemplate = ({ pageContext }) => {
  const { product } = pageContext
  const { pageLoader } = useTheme()

  const shopifyProductId = product.shopifyId
  const currentProduct = FULL_PRODUCT_DATA[shopifyProductId]

  /*============================
    CUSTOM GTM EVENT
  =============================*/
  useEffect(() => {
    let items = [
      {
        item_id: shopifyProductId,
        item_name: product.title,
        currency: "USD",
        item_brand: "Whole Betty",
        item_category: currentProduct.type,
        price: +product.variants[0].price,
        quantity: 1,
      },
    ]

    if (typeof window !== "undefined" && window.dataLayer) {
      window.dataLayer.push({
        event: "view_item",
        ecommerce: {
          item_list_name: currentProduct.type,
          items: items,
        },
      })
    }
  }, [])

  /*============================
   TABS
  =============================*/
  const [updateActiveTab, setUpdateActiveTab] = useState(currentProduct.tabs[0])

  const handleActiveTab = e => setUpdateActiveTab(e.substring(1))

  /*============================
  CONTENT
 =============================*/
  const getComponentForProduct = () => {
    switch (product.shopifyId) {
      case "gid://shopify/Product/2088294514758":
        return { Component: BerryGreenProtein, props: { tabs: currentProduct.tabs, updateActiveTab: updateActiveTab } }
      case "gid://shopify/Product/6752033734848":
        return { Component: RockAndRestore, props: { tabs: currentProduct.tabs, updateActiveTab: updateActiveTab } }
      case "gid://shopify/Product/4368664854598":
        return { Component: FullBodyCollagen, props: { tabs: currentProduct.tabs, updateActiveTab: updateActiveTab } }
      case "gid://shopify/Product/6121460564160":
        return { Component: VanillaProtein, props: { tabs: currentProduct.tabs, updateActiveTab: updateActiveTab } }
      case "gid://shopify/Product/7330569388224":
        return { Component: ChocolateProtein, props: { tabs: currentProduct.tabs, updateActiveTab: updateActiveTab } }
      case "gid://shopify/Product/7199137923264":
        return {
          Component: VanillaProteinChallenger,
          props: { tabs: currentProduct.tabs, updateActiveTab: updateActiveTab },
        }
      case "gid://shopify/Product/4679620034630":
        return { Component: SereniCalm, props: { tabs: currentProduct.tabs, updateActiveTab: updateActiveTab } }
      case "gid://shopify/Product/4423895580742":
        return { Component: WholeSleep, props: { tabs: currentProduct.tabs, updateActiveTab: updateActiveTab } }
      case "gid://shopify/Product/7502990639296":
        return { Component: NeapolitanBundle, props: { tabs: currentProduct.tabs, updateActiveTab: updateActiveTab } }
      case "gid://shopify/Product/7004126970048":
        return {
          Component: VanillaProteinPromo,
          props: { tabs: currentProduct.tabs, updateActiveTab: updateActiveTab },
        }
      case "gid://shopify/Product/7396508893376":
        return {
          Component: ChocolateProteinPromo,
          props: { tabs: currentProduct.tabs, updateActiveTab: updateActiveTab },
        }
      case "gid://shopify/Product/7004126478528":
        return {
          Component: BerryGreenProteinPromo,
          props: { tabs: currentProduct.tabs, updateActiveTab: updateActiveTab },
        }
      case "gid://shopify/Product/7004127494336":
        return {
          Component: RockAndRestorePromo,
          props: { tabs: currentProduct.tabs, updateActiveTab: updateActiveTab },
        }
      case "gid://shopify/Product/7004127854784":
        return {
          Component: FullBodyCollagenPromo,
          props: { tabs: currentProduct.tabs, updateActiveTab: updateActiveTab },
        }
      case "gid://shopify/Product/7004128313536":
        return {
          Component: FourPillarsOfHealthPromo,
          props: { tabs: currentProduct.tabs, updateActiveTab: updateActiveTab },
        }
      case "gid://shopify/Product/7345480204480":
        return { Component: WorkoutPromo, props: { tabs: currentProduct.tabs, updateActiveTab: updateActiveTab } }
      case "gid://shopify/Product/7396430971072":
        return {
          Component: VanillaProteinBuy1Get1Free,
          props: { tabs: currentProduct.tabs, updateActiveTab: updateActiveTab },
        }
      case "gid://shopify/Product/7396440572096":
        return {
          Component: ChocolateProteinBuy3Get1Free,
          props: { tabs: currentProduct.tabs, updateActiveTab: updateActiveTab },
        }
      case "gid://shopify/Product/7396462231744":
        return {
          Component: RockAndRestoreBuy3Get1Free,
          props: { tabs: currentProduct.tabs, updateActiveTab: updateActiveTab },
        }
      case "gid://shopify/Product/7396457218240":
        return {
          Component: FullBodyCollagenBuy3Get1Free,
          props: { tabs: currentProduct.tabs, updateActiveTab: updateActiveTab },
        }
      case "gid://shopify/Product/7396449091776":
        return {
          Component: BerryGreenProteinBuy3Get1Free,
          props: { tabs: currentProduct.tabs, updateActiveTab: updateActiveTab },
        }
      default:
        return null
    }
  }

  const { Component, props } = getComponentForProduct() || {}

  return (
    <>
      {/*product hero*/}
      <BuyBox shopifyProduct={product} product={currentProduct} hero={true} />

      {/* Render dynamic component */}
      <Suspense fallback={<Loader isLoader={pageLoader} />}>{Component ? <Component {...props} /> : null}</Suspense>

      {/*scrollTop**/}
      <ScrollTopButton
        isSupplement={true}
        handleActiveTab={handleActiveTab}
        isButtonOnly={!currentProduct.tabs.length}
        tabs={currentProduct.tabs}
      />

      {/*BuyBox*/}
      <BuyBox shopifyProduct={product} product={currentProduct} hero={false} />

      {/*Suggested Products*/}
      <SuggestedProducts currentItemId={shopifyProductId} imgOrientation={"horizontal"} isCart={false} />

      {/*References*/}
      {currentProduct.references && <References id={shopifyProductId} />}
    </>
  )
}
export default ProductTemplate
